import React from 'react'
import {Link} from 'react-router-dom'

import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import introStyle from '../../../utilities/Sass/introStyle.module.css'

import image1 from '../../../utilities/img/cover_2.jpg'
import image2 from '../../../utilities/img/cover_3.jpg'
import image3 from '../../../utilities/img/6.jpg'
import image4 from '../../../utilities/img/7.jpg'
import image5 from '../../../utilities/img/5.jpg'
import image6 from "../../../utilities/img/cover_5.jpg";

function Intro() {
    const slideImages = [
        image1,
        image2,
        image3,
        image4,
        image5,
        image6
    ]

    return (
      <div className={introStyle.cont__intro}>
        <div className="slide-container">
          <Fade>
            <div className="each-fade">
              <div
                className={introStyle.cont__intro_slide}
                style={{ backgroundImage: `url(${slideImages[5]})` }}
              >
                <h2>
                  Richiedi un <span>Preventivo</span> e riceverai una{" "}
                  <span>consulenza gratuita</span>
                </h2>
                <Link to="/contatti">Clicca qui!</Link>
              </div>
            </div>
            <div className="each-fade">
              <div
                className={introStyle.cont__intro_slide}
                style={{ backgroundImage: `url(${slideImages[0]})` }}
              >
                <h2>
                  Richiedi un <span>Preventivo</span> e riceverai una{" "}
                  <span>consulenza gratuita</span>
                </h2>
                <Link to="/contatti">Clicca qui!</Link>
              </div>
            </div>

            <div className="each-fade">
              <div
                className={introStyle.cont__intro_slide}
                style={{ backgroundImage: `url(${slideImages[1]})` }}
              >
                <h2>
                  Richiedi un <span>Preventivo</span> e riceverai una{" "}
                  <span>consulenza gratuita</span>
                </h2>
                <Link to="/contatti">Clicca qui!</Link>
              </div>
            </div>

            <div className="each-fade">
              <div
                className={introStyle.cont__intro_slide}
                style={{ backgroundImage: `url(${slideImages[2]})` }}
              >
                <h2>
                  Richiedi un <span>Preventivo</span> e riceverai una{" "}
                  <span>consulenza gratuita</span>
                </h2>
                <Link to="/contatti">Clicca qui!</Link>
              </div>
            </div>

            <div className="each-fade">
              <div
                className={introStyle.cont__intro_slide}
                style={{ backgroundImage: `url(${slideImages[3]})` }}
              >
                <h2>
                  Richiedi un <span>Preventivo</span> e riceverai una{" "}
                  <span>consulenza gratuita</span>
                </h2>
                <Link to="/contatti">Clicca qui!</Link>
              </div>
            </div>

            <div className="each-fade">
              <div
                className={introStyle.cont__intro_slide}
                style={{ backgroundImage: `url(${slideImages[4]})` }}
              >
                <h2>
                  Richiedi un <span>Preventivo</span> e riceverai una{" "}
                  <span>consulenza gratuita</span>
                </h2>
                <Link to="/contatti">Clicca qui!</Link>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    );
}

export default Intro

export let faqs = [
    {
        "title": "Come Riconosco l'Amianto?",
        "text": "La data di realizzazione di un manufatto può essere utile ad escludere la presenza di amianto, questo poiché a partire dal 1992 una legge ne vieta in Europa la produzione e dal 1994 è stata vietata anche la vendita e l'uso di manufatti contenenti amianto. Per manufatti realizzati prima del 1992 o per i quali non si conosce la data di realizzazione, l’analisi visiva può essere utile per escludere che lo stesso sia costituito da materiali che possano contenere amianto.",
    },
    {
        "title": "Ho l'obbligo di Comunicare la presenza dell'Amianto?",
        "text": "Si, se si tratta di materiali friabili che possono subire danni anche accidentalmente o nel caso di manufatti che presentano danni anche se di lieve entità. Chi rientra nei casi descritti deve predisporre un “Piano di Manutenzione e Controllo” conforme al D.M. 06/09/1994."
    },
    {
        "title": "A chi mi devo rivolgere per sapere se un manufatto contiene amianto?",
        "text": "I tecnici che sono formati ed abilitati a questo tipo di riconoscimento posseggono la qualifica di “Coordinatore addetto alle attività di rimozione, smaltimento e bonifica dell’Amianto”, ai sensi dell'art. 10 com.2h della Legge n. 257/92 e articolo 10 del D.P.R. 8/08/1994. I tecnici di Ever Power sono tecnici specializzati e abilitati."
    }
]

export let faqs2 = [
    {
        "title": "Come Riconosco l'Amianto?",
        "text": "La data di realizzazione di un manufatto può essere utile ad escludere la presenza di amianto, questo poiché a partire dal 1992 una legge ne vieta in Europa la produzione e dal 1994 è stata vietata anche la vendita e l'uso di manufatti contenenti amianto. Per manufatti realizzati prima del 1992 o per i quali non si conosce la data di realizzazione, l’analisi visiva può essere utile per escludere che lo stesso sia costituito da materiali che possano contenere amianto.",
    },
    {
        "title": "Ho l'obbligo di Comunicare la presenza dell'Amianto?",
        "text": "Si, se si tratta di materiali friabili che possono subire danni anche accidentalmente o nel caso di manufatti che presentano danni anche se di lieve entità. Chi rientra nei casi descritti deve predisporre un “Piano di Manutenzione e Controllo” conforme al D.M. 06/09/1994."
    },
    {
        "title": "A chi mi devo rivolgere per sapere se un manufatto contiene amianto?",
        "text": "I tecnici che sono formati ed abilitati a questo tipo di riconoscimento posseggono la qualifica di “Coordinatore addetto alle attività di rimozione, smaltimento e bonifica dell’Amianto”, ai sensi dell'art. 10 com.2h della Legge n. 257/92 e articolo 10 del D.P.R. 8/08/1994. I tecnici di Ever Power sono tecnici specializzati e abilitati."
    },
    {
        "title": "Amianto Companto o Friabile, che differenza c'è?",
        "text": "L’amianto può essere legato all’interno di un altro materiale come il cemento o plastiche come il PVC e molti altri materiali. In questi casi si parla di amianto compatto, le fibre di amianto si liberano soltanto quando il materiale si rompe o la sua superficie è logora o non più perfettamente integra. Un esempio di amianto compatto legato al cemento sono i manufatti in cemento amianto come le lastre ondulate delle coperture in eternit, le lastre piane delle pareti coibentate, i serbatoi d’acqua, le canne fumarie, le tubazioni, le fioriere, le pensiline e molti altri manufatti. Quando le fibre di amianto non sono legate ad alcun materiale o quando sono legate debolmente si parla di amianto friabile. Tali materiali sono riconoscibili poiché si sbriciolano con una lieve pressione o addirittura con il semplice tocco. Alcuni esempi di amianto friabile sono le coibentazioni di tubi, le guarnizioni di caldaie, i rivestimenti coibentanti o fonoassorbenti."
    },
    {
        "title": "Che danno può causare l'amianto alla salute?",
        "text": "L’amianto è cancerogeno cioè può provocare diversi tumori. I tumori causati dall’amianto si generano a causa dell’inalazione delle fibre ed il loro permanere nelle vie respiratorie profonde. Tali fibre infatti rimangono all’interno dell’organismo e non riescono ad essere eliminate neanche dopo molti anni. Se vuoi approfondire, ti consigliamo la pagina riguardante l'Amianto"
    },
    {
        "title": "Che sanzione è prevista per chi abbandona l'amianto?",
        "text": "Le sanzioni per l’abbandono dell’amianto che è un rifiuto pericoloso sono doppie rispetto a quelle previste per un rifiuto non pericoloso. Le sanzioni variano da 600 € a 6.000 € secondo l’articolo 34 del D.Lgs. 205/10. A queste sanzioni si aggiunge l’obbligo di rimuovere e smaltire l’amianto abbandonato, bonificare il sito di abbandono e ripristinare lo stato dei luoghi."
    },
    {
        "title": "E' possibile gettare l'amianto nel cassonetto dei Rifiuti?",
        "text": "No, il cemento amianto come l’Eternit, ad esempio, è un rifiuto pericoloso pertanto può essere bonificato e smaltito solo tramite una ditta specializzata iscritta all’Albo Gestori Ambientali alla categoria 10."
    }
]
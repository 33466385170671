import Module from "./ModuloServizi";

function Sanificazione() {
  return (
    <Module id="sanificazione">
      <h2>
        <b style={{ color: "#8bc61c" }}>
          Servizio di Sanificazione e Disinfezione
        </b>
      </h2>
      <p>
        Il servizio di sanificazione e disinfezione offerto da Ever Power s.r.l.
        è progettato per garantire ambienti sicuri e igienizzati, sia per scopi
        commerciali che residenziali. Attraverso l'utilizzo di tecnologie
        avanzate e prodotti certificati, Ever Power si impegna a eliminare
        batteri, virus e altri agenti patogeni presenti negli ambienti trattati,
        contribuendo così a proteggere la salute e il benessere di chi li abita
        o li frequenta.
      </p>
      <p>
        La sanificazione e disinfezione vengono eseguite da personale esperto e
        qualificato, che segue protocolli rigorosi per assicurare risultati
        efficaci e duraturi. Prima dell'intervento, viene effettuata un'analisi
        dettagliata dell'ambiente da trattare, al fine di identificare le aree
        critiche e pianificare il metodo migliore per ottenere una pulizia
        completa. Durante il processo, vengono impiegati prodotti biodegradabili
        e sicuri per l'ambiente, che garantiscono un'azione efficace senza
        compromettere la salute umana o danneggiare le superfici trattate.
      </p>
      <p>
        Il servizio di sanificazione e disinfezione di Ever Power è
        particolarmente adatto per una vasta gamma di ambienti, tra cui uffici,
        strutture commerciali, abitazioni private, scuole, ospedali, e molto
        altro ancora. Grazie alla sua flessibilità e personalizzazione, può
        essere adattato alle esigenze specifiche di ogni cliente, garantendo
        sempre risultati ottimali e una sensazione di sicurezza e tranquillità.
      </p>
      <p>
        Oltre ai servizi di sanificazione e disinfezione, Ever Power s.r.l.
        offre anche un servizio completo di deblattizzazione, essenziale per
        mantenere gli ambienti liberi da infestazioni di blatte, comunemente
        conosciute come scarafaggi. Le blatte non solo rappresentano un
        fastidio, ma sono anche vettori di diverse malattie, in quanto possono
        contaminare cibi e superfici con batteri e altri patogeni. Il processo
        di deblattizzazione di Ever Power inizia con un'ispezione accurata per
        individuare i punti di ingresso e le aree di nidificazione degli
        insetti. Vengono quindi applicati trattamenti mirati utilizzando
        prodotti sicuri e approvati, che eliminano le blatte senza mettere a
        rischio la salute delle persone o degli animali domestici presenti
        nell'ambiente. Questi trattamenti possono includere l'uso di esche,
        trappole e insetticidi specifici, scelti in base alla gravità
        dell'infestazione e alle caratteristiche dell'ambiente da trattare.
      </p>
    </Module>
  );
}

export default Sanificazione;

import React from 'react'

import Module from './ModuloServizi'

function ConsulenzaEnergetica() {
    return (
        <Module id="consulenzaEnergetica">
            <h2><b style={{'color': '#8bc61c'}}>CONSULENZA ENERGETICA</b></h2>

            <p>
                Sono molte le aziende che sottovalutano l’importanza di una corretta gestione delle risorse energetiche. Un errore di valutazione che a lungo andare incide sul bilancio. Eppure le opportunità non mancano. L’attuale liberalizzazione del mercato italiano da una parte e la rapida evoluzione delle tecnologie dall’altra, stanno abbassando anno dopo anno i costi per luce, gas e altre utenze, garantendo alle aziende possibilità di risparmio notevoli. Noi di Ever Power forniamo un servizio di consulenza basandoci sulle richieste di mercato attuali.
            </p>

            <p>
                Ever Power si occupa di consulenza energia e risparmio energetico aziendale. Operiamo con passione ed entusiasmo, forti di una profonda conoscenza delle dinamiche di mercato e delle nuove tecnologie in materia di risparmio energetico e di consulenza energetica. Grazie al supporto del nostro consulente energetico e ai nostri servizi per il risparmio energetico, aziende e attività industriali beneficiano fin da subito di un’amministrazione intelligente delle risorse, con margini di guadagno immediati destinati a durare nel tempo.
            </p>

            <h2>SCEGLI IL<b style={{'color': '#8bc61c'}}>RISPARMIO ENERGETICO PER LA TUA AZIENDA</b></h2>

            <p>
                Lavoriamo fianco a fianco alle aziende seguendo un metodo collaudato: analizzate insieme le esigenze e verificata la situazione nel suo complesso, procediamo con la pianificazione della migliore strategia, monitorando i risultati raggiunti attraverso report e grafici. Siamo convinti che una consulenza approfondita sia il primo passo verso la strada del risparmio energetico e dell’ottimizzazione delle risorse.
            </p>
        </Module>
    )
}

export default ConsulenzaEnergetica
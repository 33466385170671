import React from 'react'

import Module from './ModuloServizi'

function RimozioneRifiuti() {
    return (
        <Module id="rimozioneRifiuti">
            <h2>RIMOZIONE <b style={{'color': '#8bc61c'}}>RIFIUTI PERICOLOSI E NON</b></h2>

            <p>
                Ever Power è un'azienda specializzata nei servizi di raccolta, trasporto, smaltimento e recupero dei rifiuti in tutta la Campania. Ci occupiamo della Gestione dei Rifiuti, e siamo Iscritti presso l’albo nazionale Gestori ambientali della Campania.
                Il nostro staff è altamente qualificato per la Rimozione dei Rifiuti Speciali; Siamo abilitati al trasporto e i nostri Ingegneri Ambientali offrono consulenza ambientale per definire insieme la tipologia dei Rifiuti.
            </p>

            <h2>Categoria di<b style={{'color': '#8bc61c'}}> Rifiuti Speciali e Pericolosi</b></h2>

            <ul>
                <li>Amianto</li>
                <li>Apparecchiature Elettriche</li>
                <li>Pile, Batterie, Accumulatori</li>
                <li>Inerti</li>
                <li>Guaina Bituminosa</li>
                <li>Legno</li>
                <li>Vetro</li>
                <li>Ferro ed Acciaio</li>
                <li>Alluminio</li>
                <li>Rifiuti da Gommisti</li>
                <li>Olio per Motore</li>
                <li>Rifiuti da Officine e Autocarrozzerie</li>
                <li>Rifiuti da Tipografie, Serigrafie - Litografie</li>
                <li>Toner, Cartucce, Nastri Stampanti</li>
                <li>Carta e Cartone</li>
                <li>Tubi Neon</li>
                <li>Rifiuti da Ristorazione</li>
                <li>Plastiche Varie</li>
                <li>Rifiuti Vegetali</li>
                <li>Rifiuti Ingombranti</li>
            </ul>
        </Module>
    )
}

export default RimozioneRifiuti

import React from "react";

import Module from "./ModuloServizi";

function AnalisiRifiuti() {
  return (
    <Module id="analisiRifiutiCer">
      <h2>
        <b style={{ color: "#8bc61c" }}>
          ANALISI E CARATTERIZZAZIONE DEI RIFIUTI
        </b>
      </h2>

      <p>
        I produttori di rifiuti sono obbligati a Classificare e Caratterizzare i
        rifiuti prodotti prima delle operazioni di Smaltimento del rifiuto, per
        tale ragione Ever Power supporta i propri clienti nell'attività di
        Classificazione e Caratterizzazione dei Rifiuti.
      </p>

      <h2>
        COSA INTENDIAMO CON{" "}
        <b style={{ color: "#8bc61c" }}>
          ANALISI E CARATTERIZZAZIONE DEI RIFIUTI
        </b>
      </h2>

      <p>
        All’interno dell’Unione Europea ogni rifiuto prodotto viene associato ad
        un codice contenuto nell’Elenco Europeo Rifiuti. Tale codice prende il
        nome di Codice Europeo Rifiuti il cui acronimo largamente utilizzato nel
        settore della gestione rifiuti è CER. Il CER viene assegnato in primo
        luogo in funzione dell’attività che ha prodotto il rifiuto e poi in
        funzione della sua composizione. Prima di smaltire i rifiuti questi
        devono essere correttamente caratterizzati, il che vuol dire
        identificare quale CER del Catalogo Europeo dei Rifiuti deve essere
        assegnato al rifiuto. Gestire un rifiuto in assenza di una non corretta
        assegnazione del CER, oltre ad essere un reato, potrebbe precluderne il
        trasporto ed il conferimento all’impianto di recupero o smaltimento
        finale.
      </p>

      <p>
        Il processo di caratterizzazione infatti determina le caratteristiche
        chimiche, fisiche e biologiche del rifiuto, in modo da individuare il
        tipo di trasporto, stoccaggio e trattamento possibili per tale rifiuto
        scongiurando rischi per la salute e pere l’ambiente. Preliminarmente
        occorre distinguere il rifiuto tra rifiuto urbani o rifiuti speciali,
        tale onere è in capo al produttore del rifiuto.Da tale divisione
        dipenderà tutta la successiva gestione e le procedure per il trattamento
        e lo smaltimento in impianti per rifiuti solidi urbani o rifiuti
        speciali. Necessita poi individuare se un rifiuto è"non pericoloso" o
        "pericoloso".
        <br />
        <b style={{ color: "#8bc61c" }}>
          L ’art. 258 comma 4 del D.Lgs 152/2006 punisce la mancata
          caratterizzazione dei rifiuti con la Sanzione Penale dell’arresto fino
          a 2 anni.
        </b>
      </p>
    </Module>
  );
}

export default AnalisiRifiuti;

import React from 'react'

import {Link, Route, Switch} from 'react-router-dom'
import BonificaAmianto from './BonificaAmianto.js'
import RimozioneRifiuti from './RimozioneRifiuti.js'
import ConsulenzaAmbientale from './ConsulenzaAmbientale.js'
import RipristinoDiTettoie from './RipristinoDiTettoie.js'
import AnalisiRifiuti from './AnalisiRifiuti.js'
import RimozioneGuaine from './RimozioneGuaine.js'
import ConsulenzaEnergetica from './ConsulenzaEnergetica.js'
import PannelliFotovoltaici from './PannelliFotovoltaici.js'
import Sanificazione from "./Sanificazione.js";

import serviceStyle from '../../utilities/Sass/serviceModule.module.css'

function Servizi() {
    let movesToSection = (el) => {
        setTimeout(() => {
            let offsetTop = document.getElementById(el).offsetTop;

            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            })
        }, 0)
    }

    return (
      <div className={serviceStyle.cont__service}>
        <div className={serviceStyle.cont__service_description}>
          <h2>
            I nostri <b style={{ color: "#8bc61c" }}>SERVIZI</b>
          </h2>

          <p style={{lineHeight: "30px"}}>
            Ever Power si distingue per il suo impegno costante nell'assicurare
            un ambiente sano e sicuro attraverso un approccio professionale,
            responsabile e rispettoso dell'ambiente. Prima di procedere con
            qualsiasi attività di bonifica, l'azienda esegue un'attenta analisi
            del prodotto e del contesto circostante, elaborando un piano
            dettagliato che viene presentato alle autorità competenti e agli
            organi di vigilanza. Questo processo garantisce una gestione
            accurata e conforme alle normative vigenti.
          </p>
          <p style={{lineHeight: "30px"}}>
            Durante le operazioni di bonifica, Ever Power adotta misure rigorose
            per minimizzare il rischio di esposizione di terzi, confinando il
            luogo della bonifica e utilizzando agenti incapsulanti specifici per
            prevenire la diffusione di sostanze nocive come l'amianto. Inoltre,
            l'azienda pone una grande attenzione alla sicurezza dei propri
            operatori, fornendo loro dispositivi di protezione adeguati e
            costantemente aggiornati.
          </p>
          <p style={{lineHeight: "30px"}}>
            Una volta completata la bonifica, il materiale contenente amianto
            viene sigillato in modo sicuro con teli appropriati e trasportato
            utilizzando mezzi autorizzati e conformi alle normative ambientali.
            Infine, Ever Power si impegna a smaltire il materiale in centri
            specializzati e autorizzati, garantendo una gestione responsabile e
            conforme alle disposizioni di legge.
          </p>
          <p style={{lineHeight: "30px"}}>
            In sintesi, Ever Power si distingue per la sua serietà, competenza e
            rispetto nell'affrontare le sfide legate alla bonifica e al
            trattamento dei materiali pericolosi, assicurando una gestione
            completa e conforme alle normative ambientali per la salvaguardia
            dell'ambiente e della salute pubblica.
          </p>
        </div>

        <ul className={serviceStyle.cont__service_list}>
          <li>
            <Link
              to="/servizi/bonificaAmianto"
              onClick={() => movesToSection("bonificaAmianto")}
            >
              RIMOZIONE, BONIFICA E SMALTIMENTO AMIANTO
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/rimozioneGuaine"
              onClick={() => movesToSection("rimozioneGuaine")}
            >
              RIMOZIONE GUAINE BITUMINOSE
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/rimozioneRifiuti"
              onClick={() => movesToSection("rimozioneRifiuti")}
            >
              RIMOZIONE RIFIUTI PERICOLOSI E NON
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/consulenzaEnergetica"
              onClick={() => movesToSection("consulenzaEnergetica")}
            >
              CONSULENZA ENERGETICA
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/consulenzaAmbientale"
              onClick={() => movesToSection("consulenzaAmbientale")}
            >
              CONSULENZA AMBIENTALE
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/installazionePannelliFotovoltaici"
              onClick={() =>
                movesToSection("installazionePannelliFotovoltaici")
              }
            >
              INSTALLAZIONE PANNELLI FOTOVOLTAICI
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/ripristinoTettoie"
              onClick={() => movesToSection("ripristinoTettoie")}
            >
              RIPRISTINO DI TETTOIE DOMESTICHE E CAPANNONI INDUSTRIALI
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/gestioneRifiutiAziendali"
              onClick={() => movesToSection("gestioneRifiutiAziendali")}
            >
              GESTIONE RIFIUTI AZIENDALI
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/analisiRifiutiCer"
              onClick={() => movesToSection("analisiRifiutiCer")}
            >
              ANALISI RIFIUTI CER
            </Link>
          </li>
          <li>
            <Link
              to="/servizi/sanificazione"
              onClick={() => movesToSection("sanificazione")}
            >
              SANIFICAZIONE E DISINFEZIONE
            </Link>
          </li>
        </ul>

        <Switch>
          <Route
            path="/servizi/bonificaAmianto"
            render={() => <BonificaAmianto />}
          />
          <Route
            path="/servizi/rimozioneRifiuti"
            render={() => <RimozioneRifiuti />}
          />
          <Route
            path="/servizi/consulenzaAmbientale"
            render={() => <ConsulenzaAmbientale />}
          />
          <Route
            path="/servizi/ripristinoTettoie"
            render={() => <RipristinoDiTettoie />}
          />
          <Route
            path="/servizi/analisiRifiutiCer"
            render={() => <AnalisiRifiuti />}
          />
          <Route
            path="/servizi/rimozioneGuaine"
            render={() => <RimozioneGuaine />}
          />
          <Route
            path="/servizi/consulenzaEnergetica"
            render={() => <ConsulenzaEnergetica />}
          />
          <Route
            path="/servizi/installazionePannelliFotovoltaici"
            render={() => <PannelliFotovoltaici />}
          />
          <Route
            path="/servizi/gestioneRifiutiAziendali"
            render={() => <RimozioneRifiuti />}
          />
          <Route
            path="/servizi/sanificazione"
            render={() => <Sanificazione />}
          />
        </Switch>
      </div>
    );
}

export default Servizi

import React from 'react'

import homeStyle from '../../../utilities/Sass/homeStyle.module.css'

import Intro from '../Intro/Intro'

import Services from './Services'

import News from './News'

import FAQ from './FAQ'

import ContactModule from '../../ContactModule/ContactModule'

import {arrayImages} from './Collabs'

import hands from '../../../utilities/img/icon/mano.png'
import news from '../../../utilities/img/icon/news.png'
import point from '../../../utilities/img/icon/PUNTO.png'

function Home() {
    

    return (
      <div className={homeStyle.cont__home}>
        <Intro />

        <div className={homeStyle.cont__home_sectionDescription}>
          <h2>
            Chi <b style={{ color: "#8bc61c" }}>siamo</b>
          </h2>

          <p>
            Fondata nel 2015, la nostra azienda giovane e dinamica si è
            rapidamente affermata come leader nel settore della rimozione e
            smaltimento dell'amianto nel Sud Italia, diventato presto una delle
            realtà più solide e competetive del settore. La nostra visione
            imprenditoriale ci ha portato ad evolverci continuamente, ampliando
            le nostre competenze e servizi per rispondere alle esigenze sempre
            crescenti dei nostri clienti e del mercato.
          </p>

          <p>
            L’esecuzione dei lavori è sempre preceduta da una fase di studio e
            progettazione dettagliata, svolta da professionisti interni. Questi
            professionisti analizzano e programmano l'intervento in tutti i suoi
            dettagli, valutando l'applicazione delle tecniche e delle tecnologie
            più appropriate per ogni specifico cantiere, tenendo conto della
            localizzazione e dei requisiti di sicurezza del contesto.
            L'organizzazione del cantiere, la minimizzazione dell'impatto
            ambientale e il rispetto dei tempi assegnati sono punti di forza
            fondamentali di Ever Power. Questi aspetti sono considerati
            presupposti necessari per ridurre gli oneri legati alle attività.
            Ogni intervento è eseguito da personale altamente specializzato, che
            è costantemente aggiornato tramite corsi di formazione specifici.
            Questa attenzione alla formazione continua garantisce che i
            dipendenti di Ever Power siano sempre al passo con le ultime
            normative e tecnologie nel campo del trattamento dei rifiuti e delle
            attività ambientali.
          </p>

          <p>
            In sintesi, Ever Power s.r.l. si distingue per la sua capacità di
            gestire un'ampia gamma di servizi ambientali, grazie a un team di
            tecnici competenti nel campo dell’ingegneria ambientale e personale
            qualificato per il trattamento dei materiali speciali. La
            combinazione di competenze tecniche, attrezzature adeguate e un
            approccio dettagliato alla progettazione e all'esecuzione dei lavori
            rende Ever Power un partner affidabile per enti pubblici e privati
            in tutto il territorio nazionale.
          </p>

          <h2>
            La nostra <b style={{ color: "#8bc61c" }}>Crescita</b>
          </h2>

          <p>
            La nostra crescita è stata costante e mirata. Dopo aver consolidato
            la nostra posizione nel mercato dell'amianto, abbiamo esteso i
            nostri servizi alla rimozione, trasporto e smaltimento di tutte le
            tipologie di rifiuti, sia pericolosi che non pericolosi, operando
            sia per conto proprio che per conto terzi.Operando sia nel settore
            pubblico che privato.  La nostra capacità di gestire ogni fase del
            processo, dal trasporto allo smaltimento all’espletamento di
            pratiche burocratiche, ci permette di offrire soluzioni complete e
            sicure ai nostri clienti.
          </p>

          <h2>Specializzazioni e Servizi Completi</h2>

          <p>
            Oltre alla gestione dei rifiuti, ci siamo specializzati nelle
            bonifiche di siti inquinati e negli studi di impatto ambientale,
            garantendo interventi efficaci e rispettosi delle normative vigenti.
            Recentemente, abbiamo ampliato ulteriormente il nostro range di
            servizi nel settore ambientale, acquisendo mezzi di ultima
            generazione per operazioni di disinfestazione, derattizzazione e
            sanificazione di edifici pubblici e privati. Siamo,inoltre, esperti
            nella progettazione, fornitura e posa in opera di coperture sia
            civili che industriali, nonché nella realizzazione di impianti
            fotovoltaici e di efficientamento energetico. Queste
            specializzazioni ci permettono di offrire soluzioni integrate per
            migliorare l'efficienza energetica e la sostenibilità delle
            strutture civili, indistriali sia pubbliche che private.
          </p>

          <h2>Professionalità, Sicurezza e Risparmio</h2>

          <p>
            La nostra azienda si distingue per la serietà, professionalità e
            attenzione alla sicurezza. Ogni intervento è preceduto da uno studio
            preliminare condotto da professionisti esperti, garantendo così
            soluzioni efficaci e sicure. La nostra grande professionalità ci
            permette non solo di assicurare interventi impeccabili, ma anche di
            offrire ottimi risparmi economici ai nostri clienti.
          </p>

          <p>
            La nostra azienda è in continua evoluzione, sempre pronta ad
            adattarsi alle nuove sfide e a innovare nel settore ambientale.
            Grazie a una articolata organizzazione aziendale, siamo in grado di
            adempiere a tutte le esigenze dei nostri clienti, offrendo soluzioni
            su misura e di alta qualità.
          </p>
        </div>

        <div className={homeStyle.cont__home_sectionDescription}>
          <h2>
            I nostri <b style={{ color: "#8bc61c" }}>Servizi</b>
          </h2>

          <p>
            Ever Power fornisce servizi completi di bonifica, smaltimento e
            trasporto dell'amianto, seguendo protocolli rigorosi che includono
            l'analisi preliminare, il confinamento dell'area di bonifica, il
            trattamento con agenti incapsulanti, l'utilizzo di dispositivi di
            sicurezza, il trasporto autorizzato e lo smaltimento in centri
            specializzati. Inoltre, offre un servizio sicuro per la rimozione e
            il trasporto delle guaine bituminose, garantendo il rispetto delle
            normative vigenti e la protezione dell'ambiente. Gestisce la
            raccolta, il trasporto e lo smaltimento di rifiuti, speciali
            pericolosi e non pericolosi, collaborando con impianti autorizzati
            per garantire la conformità e la sicurezza. Ever Power offre servizi
            di analisi di caratterizzazione dei rifiuti, determinando le
            caratteristiche specifiche per garantire la gestione appropriata e
            la tutela del produttore. La società fornisce consulenze energetiche
            dettagliate per aiutare i clienti a comprendere e ottimizzare i loro
            consumi energetici, migliorando le performance dei loro sistemi.
            Supporta le imprese nell'adempimento delle normative ambientali,
            assicurando operazioni conformi e sicure. Inoltre, installa pannelli
            fotovoltaici, promuovendo la produzione di energia pulita e
            sostenibile, con benefici per l'ambiente e la salute pubblica. Ever
            Power offre una gestione completa dei rifiuti per le aziende,
            promuovendo pratiche etiche e sostenibili dal punto di vista
            ambientale. Si occupa del ripristino di tettoie domestiche e
            capannoni industriali, utilizzando materiali certificati e
            sfruttando sgravi fiscali per offrire soluzioni economicamente
            vantaggiose. L'azienda si occupa, inoltre di ristrutturazioni civili
            ed industriali, ripristino di impianti elettrici e idraulici,
            fornendo interventi di alta qualità per assicurare il miglior
            risultato possibile.
          </p>

          <p>
            La società fornisce interventi di pulizia, disinfezione,
            disinfestazione, sanificazione per uffici, capannoni, aree esterne,
            cinema e teatri, sia pubblici che privati, garantendo ambienti
            salubri e igienici. Ever Power offre anche servizi di
            deblattizzazione e derattizzazione, utilizzando metodi efficaci e
            sicuri per eliminare infestazioni e proteggere gli ambienti da
            parassiti indesiderati. Con un team di tecnici altamente qualificati
            e costantemente aggiornati, Ever Power s.r.l. è in grado di offrire
            soluzioni complete e personalizzate, affrontando con competenza e
            professionalità le sfide ambientali dei propri clienti su tutto il
            territorio nazionale. Grazie all'ampia gamma di servizi offerti,
            l'azienda è il partner ideale per enti pubblici, società e privati
            che cercano un approccio integrato e sostenibile nella gestione
            ambientale.
          </p>
        </div>

        <Services />

        <img
          src={hands}
          className={homeStyle.cont__home_sectionCollabs_image}
          alt="Clienti - Ever Power"
        />

        <h2>Tra i nostri principali Clienti</h2>

        <ul className={homeStyle.cont__home_sectionCollabs}>
          {arrayImages.map((el, index) => (
            <li key={index}>
              <img src={el} alt={index} />
            </li>
          ))}
        </ul>

        <img
          src={news}
          className={homeStyle.cont__home_sectionCollabs_image}
          alt="Notizie - Ever Power"
        />

        <h2>Parlano di Noi</h2>

        <News />

        <img
          src={point}
          className={homeStyle.cont__home_sectionCollabs_image}
          alt="Punto Interrogativo - Ever Power"
        />

        <h2>F.A.Q</h2>

        <p style={{ color: "#666", fontWeight: "600" }}>
          Qui vi mostriamo alcune delle{" "}
          <b style={{ color: "#8bc61c" }}>domande</b> più frequenti che ci
          vengono poste.
        </p>

        <FAQ section="Home" />

        <ContactModule />
      </div>
    );
}

export default Home

import React from "react";

import aboutStyle from "../../utilities/Sass/chiSiamo.module.css";
import Immagine1Prima from "../../utilities/img/lavori/1prima.jpg";
import Immagine1Dopo from "../../utilities/img/lavori/1dopo.jpg";
import Immagine2Prima from "../../utilities/img/lavori/2prima.jpg";
import Immagine2Dopo from "../../utilities/img/lavori/2dopo.jpg";
import Immagine3Prima from "../../utilities/img/lavori/3prima.jpg";
import Immagine3Dopo from "../../utilities/img/lavori/3dopo.jpg";
import Immagine4Prima from "../../utilities/img/lavori/4prima.jpg";
import Immagine4Dopo from "../../utilities/img/lavori/4dopo.jpg";
import Immagine5Prima from "../../utilities/img/lavori/5prima.jpg";
import Immagine5Dopo from "../../utilities/img/lavori/5dopo.jpg";
import Immagine6Prima from "../../utilities/img/lavori/6prima.jpg";
import Immagine6Dopo from "../../utilities/img/lavori/6dopo.jpg";
import Immagine7Prima from "../../utilities/img/lavori/7prima.jpg";
import Immagine7Dopo from "../../utilities/img/lavori/7dopo.jpg";
import Immagine8Prima from "../../utilities/img/lavori/8prima.jpg";
import Immagine8Dopo from "../../utilities/img/lavori/8dopo.jpg";

import Immagine1Prima_2 from "../../utilities/img/lavori/1prima_1.jpg";
import Immagine1Dopo_2 from "../../utilities/img/lavori/1dopo_1.jpg";
import Immagine2Prima_2 from "../../utilities/img/lavori/2prima_1.jpg";
import Immagine2Dopo_2 from "../../utilities/img/lavori/2dopo_1.jpg";
import Immagine3Prima_2 from "../../utilities/img/lavori/3prima_1.jpg";
import Immagine3Dopo_2 from "../../utilities/img/lavori/3dopo_1.jpg";
import Immagine4Prima_2 from "../../utilities/img/lavori/4prima_1.jpg";
import Immagine4Dopo_2 from "../../utilities/img/lavori/4dopo_1.jpg";
import Immagine5Prima_2 from "../../utilities/img/lavori/5prima_1.jpg";
import Immagine5Dopo_2 from "../../utilities/img/lavori/5dopo_1.jpg";
import Immagine6Prima_2 from "../../utilities/img/lavori/6prima_1.jpg";
import Immagine6Dopo_2 from "../../utilities/img/lavori/6dopo_1.jpg";
import Immagine7Prima_2 from "../../utilities/img/lavori/7prima_1.jpg";
import Immagine7Dopo_2 from "../../utilities/img/lavori/7dopo_1.jpg";
import Immagine8Prima_2 from "../../utilities/img/lavori/8prima.jpg";
import Immagine8Dopo_2 from "../../utilities/img/lavori/8dopo.jpg";

function Work() {
  const lavori = [
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 1,
      titolo: "COLACEM SPA",
      data: "01/01/2023",
      localita: "SESTO CAMPANO",
      immaginePrima: Immagine1Prima,
      immagineDopo: Immagine1Dopo,
      immaginePrima_2: Immagine1Prima_2,
      immagineDopo_2: Immagine1Dopo_2,
    },
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 2,
      titolo: "DI COSTANZO",
      data: "02/01/2023",
      localita: "ORTA DI ATELLA",
      immaginePrima: Immagine2Prima,
      immagineDopo: Immagine2Dopo,
      immaginePrima_2: Immagine2Prima_2,
      immagineDopo_2: Immagine2Dopo_2,
    },
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 3,
      titolo: "FRATELLI NAPOLITANO",
      data: "03/01/2023",
      localita: "EBOLI",
      immaginePrima: Immagine3Prima,
      immagineDopo: Immagine3Dopo,
      immaginePrima_2: Immagine3Prima_2,
      immagineDopo_2: Immagine3Dopo_2,
    },
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 4,
      titolo: "IEMMA ALFREDO",
      data: "04/01/2023",
      localita: "VITULAZIO",
      immaginePrima: Immagine4Prima,
      immagineDopo: Immagine4Dopo,
      immaginePrima_2: Immagine4Prima_2,
      immagineDopo_2: Immagine4Dopo_2,
    },
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 5,
      titolo: "IEMMA ALFREDO",
      data: "05/01/2023",
      localita: "VITULAZIO",
      immaginePrima: Immagine5Prima,
      immagineDopo: Immagine5Dopo,
      immaginePrima_2: Immagine5Prima_2,
      immagineDopo_2: Immagine5Dopo_2,
    },
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 6,
      titolo: "IEMMA CESARE",
      data: "06/01/2023",
      localita: "PASTORANO",
      immaginePrima: Immagine6Prima,
      immagineDopo: Immagine6Dopo,
      immaginePrima_2: Immagine6Prima_2,
      immagineDopo_2: Immagine6Dopo_2,
    },
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 7,
      titolo: "MIRAGLIA OLIVE SRL",
      data: "07/01/2023",
      localita: "Z.I. ASI CARINARO",
      immaginePrima: Immagine7Prima,
      immagineDopo: Immagine7Dopo,
      immaginePrima_2: Immagine7Prima_2,
      immagineDopo_2: Immagine7Dopo_2,
    },
    {
      categoria: "Rimozione e Bonifica Amianto",
      id: 8,
      titolo: "Inventare un nome",
      data: "08/01/2023",
      localita: "MONTEFORTE IRPINO",
      immaginePrima: Immagine8Prima,
      immagineDopo: Immagine8Dopo,
      immaginePrima_2: Immagine8Prima_2,
      immagineDopo_2: Immagine8Dopo_2,
    },
  ];

  return (
    <div className={aboutStyle.cont__about}>
      <div className={aboutStyle.cont__about_description}>
        <h2>
          I nostri <b style={{ color: "#8bc61c" }}>lavori</b>
        </h2>
        <p style={{ lineHeight: "30px" }}>
          Ever Power si distingue per la sua efficienza nel coordinare le
          operazioni di bonifica e rimozione, rispettando rigorosi standard di
          sicurezza e tempi di consegna. La velocità con cui vengono eseguiti i
          lavori è testimonianza dell'organizzazione interna dell'azienda e
          della competenza del suo personale altamente specializzato. Grazie a
          una pianificazione meticolosa e all'impiego di risorse avanzate, Ever
          Power è in grado di completare i progetti in tempi rapidi, garantendo
          nel contempo la massima qualità e conformità alle normative. La
          gestione dei lavori da parte di Ever Power è improntata alla massima
          trasparenza e comunicazione con il cliente. Prima dell'inizio di ogni
          intervento, vengono concordati dettagli e obiettivi, e durante il
          processo di esecuzione vengono forniti aggiornamenti regolari sullo
          stato dei lavori. Questo approccio orientato al cliente assicura una
          collaborazione efficace e una soddisfazione complessiva.
        </p>
      </div>

      <div className={aboutStyle.cont__lavori}>
        {lavori.map((lavoro) => (
          <div className="lavoro" key={lavoro.id}>
            <h3>{lavoro.titolo}</h3>
            <p>
              <strong>Data del lavoro:</strong> {lavoro.data}
            </p>
            <p>
              <strong>Località:</strong> {lavoro.localita}
            </p>
            <p>
              <strong>Categoria:</strong> {lavoro.categoria}
            </p>

            <div className="immagini">
              <img src={lavoro.immaginePrima} alt="Immagine Prima" />
              <img src={lavoro.immaginePrima_2} alt="Immagine Prima 1" />
              <img src={lavoro.immagineDopo} alt="Immagine Dopo" />
              <img src={lavoro.immagineDopo_2} alt="Immagine Prima 1" />{" "}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Work;

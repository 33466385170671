import './App.css';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'


import Home from './components/Main/Home/Home'
import News from './components/Main/Home/News'
import FAQ from './components/Main/Home/FAQ'
import ChiSiamo from './components/Main/ChiSiamo/ChiSiamo'
import Servizi from './components/Servizi/Servizi'
import Contatti from './components/Contatti/Contatti'
import Work from "./components/Work/Work";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <Switch>
          <Route path="/lavori">
            <Work section="lavori" />
          </Route>

          <Route path="/lavora-con-noi">
            <Contatti section="lavora-con-noi" />
          </Route>

          <Route path="/contatti">
            <Contatti section="contatti" />
          </Route>

          <Route path="/faq">
            <FAQ section="Inside" />
          </Route>

          <Route path="/notizie">
            <News />
          </Route>

          <Route path="/servizi">
            <Servizi />
          </Route>

          <Route path="/chiSiamo">
            <ChiSiamo />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;

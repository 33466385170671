import React from 'react'

import {Link} from 'react-router-dom'

import footerStyle from '../../utilities/Sass/footerStyle.module.css'
import Logo from '../../utilities/img/LOGO.png'

import instagram from '../../utilities/img/icon/instagram.png'
import facebook from '../../utilities/img/icon/facebook.png'
import homeIcon from '../../utilities/img/icon/home.png'
import mail from '../../utilities/img/icon/mail.png'
import telefono from '../../utilities/img/icon/telefono.png'


function Footer() {
    return (
      <>
        <div className={footerStyle.cont__footer_mission}>
          <h2>MISSION</h2>

          <p>
            "Siamo un’impresa dedita alla salvaguardia dell’ambiente. Lavoriamo
            per costruire un futuro migliore rimediando agli errori delle
            generazioni passate, liberando l’ambiente dai materiali altamente
            contaminanti per la nostra salute. <br /> Lavoriamo per le
            generazioni future. Fondiamo il nostro lavoro sulla passione e
            l’innovazione. Sulla forza e lo sviluppo delle nostre competenze."
          </p>
        </div>

        <div className={footerStyle.cont__footer}>
          <Link to="/">
            <img src={Logo} alt="Header Icon" />
          </Link>

          <ul>
            <li>
              <a href="https://www.instagram.com/everpower__/">
                <img src={instagram} alt="Header Icon" />

                <span>EverPower</span>
              </a>

              <a href="https://www.facebook.com/EverPowerSrl/">
                <img src={facebook} alt="Facebook" />

                <span>EverPower</span>
              </a>
            </li>

            <li id="info">
              <img src={homeIcon} alt="Indirizzo Everpower" />

              <a href="https://goo.gl/maps/F6Q38YLX9JC7XQsT6">
                Viale Del Consiglio D’Europa n42, Santa Maria Capua Vetere,
                81055, Campania (CE)
              </a>
            </li>

            <li id="info">
              <img src={mail} alt="Email EverPower" />

              <a href="mailto: info@everpower.it">info@everpower.it</a>
              <a href="mailto: everpower.srls@pec.it">everpower.srls@pec.it</a>
            </li>

            <li id="info">
              <img src={telefono} alt="Numeri Everpower" />

              <a href="tel:0823793131">0823 793131</a>
              <a href="tel:3881552509">388 1552509</a>
            </li>

            <li>
              <Link id="preventivo" to="/contatti">
                Richiedi un preventivo
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
}

export default Footer

import React from 'react'

import Module from './ModuloServizi'

function RipristinoDiTettoie() {
    return (
        <Module id="ripristinoTettoie">
            <h2><b style={{'color': '#8bc61c'}}>RIPRISTINO TETTOIE DOMESTICHE E CAPANNONI INDUSTRIALI</b></h2>

            <p>
                La Ever Power si occupa del ripristino di Tettoie Domestiche e Capannoni industriali con grande professionalità, lavorando con rapidità e serietà.

                Quale Copertura scegliere nel rifacimento del tetto? Esistono diverse tipologie di coperture diverse tra loro e con costi differenti.
                I nostri Professionisti hanno selezionato le coperture dei tetti in base ai materiali impiegati ed i costi da affrontare.

                I Materiali che utilizziamo sono improntati alla ricerca del miglior rapporto qualità/prezzo ed alla migliore tecnologia per coibentare il tetto e soddisfare tutte le richieste del cliente.
            </p>
        </Module>
    )
}

export default RipristinoDiTettoie

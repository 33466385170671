import React from 'react'


import contactStyle from '../../utilities/Sass/contactStyle.module.css'

import ContactModule from '../ContactModule/ContactModule'
import homeIcon from '../../utilities/img/icon/home.png'
import mail from '../../utilities/img/icon/mail.png'
import telefono from '../../utilities/img/icon/telefono.png'

function Contatti(props) {
    return (
        <div className={contactStyle.cont__contactPage}>
            <div className={contactStyle.cont__contactPage_moduleContact}>
                {props.section === "lavora-con-noi" && (
                    <ContactModule workForUs />
                )} 

                {props.section === "contatti" && (
                    <ContactModule />
                )}
            </div>

            <div className={contactStyle.cont__contactPage_information}>
                <p className={contactStyle.cont__contactPage_information_mission}>
                    <h2>Noi di Ever Power..</h2>

                    ..assicuriamo assoluta serietà nel nostro lavoro, gestendo le vostre richieste in maniera professionale.
                    Contattateci per qualsiasi informazione, saremo più che lieti di rispondere, nel minor tempo possibile, a qualsivoglia tipo di domanda.
                </p>

                <h2>Contatti</h2>

                <ul className={contactStyle.cont__contactPage_information_contact}>
                    <li>
                        <img src={homeIcon} alt="Indirizzo Everpower" />

                        <a href="https://goo.gl/maps/F6Q38YLX9JC7XQsT6">Viale Del Consiglio D’Europa n42, Santa Maria Capua Vetere, 81055, Campania (CE)</a>
                    </li>

                    <li>
                        <img src={mail} alt="Email EverPower" />

                        <a href="mailto: info@everpower.it">info@everpower.it</a> 
                        <a href="mailto: everpower.srls@pec.it">everpower.srls@pec.it</a>
                    </li>
                        
                    <li>
                        <img src={telefono} alt="Numeri Everpower" />

                        <a href="tel:0823793131">0823 793131</a>
                        <a href="tel:3881552509">388 1552509</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Contatti

import React from 'react'

import Module from './ModuloServizi'

function ConsulenzaAmbientale() {
    return (
        <Module id="consulenzaAmbientale">
            <h2><b style={{'color': '#8bc61c'}}>CONSULENZA AMBIENTALE</b></h2>

            <p>
                La Ever Power fa affidamento su esperti con una perfetta conoscenza nel campo delle consulenze ambientali per offrire un servizio completo, che riguarda diversi aspetti come l’ottenimento delle autorizzazioni necessarie per l’esercizio di un’attività, i monitoraggi per le restituibilità degli ambienti bonificati, la gestione dei rifiuti, l’igiene, le necessità di bonifica e così via.

                Le Consulenze ambientali di Ever Power sono indirizzate per le piccole, medie e grandi imprese: il suo monitoraggio e controllo ambientale permette di avere sempre piena coscienza di ogni situazione, grazie al contributo offerto dalle più moderne tecniche e strumentazioni.
            </p>

            <p>
                Affidarsi a un professionista nel settore delle consulenze ambientali significa non doversi più preoccupare dei diversi aspetti relativi all’impatto della propria attività sull’ambiente.
                Dai rifiuti alle bonifiche, dal monitoraggio ambientale allo smantellamento delle strutture in amianto, il nostro Team di Consulenti garantisce la massima affidabilità e il rispetto di tutte le normative del settore, agendo in completa sicurezza per il risanamento ottimale dei territori inquinati grazie a un’offerta di servizi di consulenza improntata alla Professionalità. Contattateci per saperne di più.

                La Ever Power affianca le Aziende e, tramite una Consulenza Professionale, permette l'ottimizzazione dei costi.
            </p>
        </Module>
    )
}

export default ConsulenzaAmbientale

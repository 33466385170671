import React from 'react'

import homeStyle from '../../../utilities/Sass/homeStyle.module.css'

import image1 from '../../../utilities/img/news/repubblica.PNG'
import image2 from '../../../utilities/img/news/corriere.PNG'
import image3 from '../../../utilities/img/news/ansa.PNG'
import image4 from '../../../utilities/img/news/dodici.PNG'


function News() {
    let objectNews = [
        {
            "image": image1,
            "title": "Professionalità, competenza e concretezza. Lo smaltimento dell'amianto è più facile.",
            "link": "https://napoli.repubblica.it/dossier-adv/eccellenze-della-campania/2020/06/05/news/professionalita_competenza_e_concretezza_lo_smaltimento_dell_amianto_e_piu_facile-258508756/"
        },
        {
            "image": image2,
            "title": "igiene e Tutela del Territorio, Soluziooni Everpower",
            "link": "https://www.corrieredelleconomia.it/2020/06/05/igiene-e-tutela-del-territorio-le-soluzioni-di-ever-power/"
        },
        {
            "image": image3,
            "title": "Ever Power: Siamo Dediti alla salvaguardia dell'ambiente",
            "link": "https://www.ansa.it/pressrelease/campania/2020/06/05/ever-power-siamo-dediti-alla-salvaguardia-dellambiente-_2b9f8168-5e5d-4cac-960f-69a6aed7ae83.html"
        },
        {
            "image": image4,
            "title": "Eccellenza nel settore dello smaltimento rifiuti",
            "link": "https://www.dodicimagazine.com/pdf/ottobre-2020/mobile/index.html#p=38"
        }
    ]


    return (
        <ul className={homeStyle.cont__home_sectionNews}>
            {objectNews.map(el => {
                return (
                    <li key={el.title}>
                        <a href={el.link}>
                            <img src={el.image} alt={el.title} />
                        </a>
                    </li>
                )
            })}
        </ul>
    )
}

export default News

import React from 'react'

import insideStyle from '../../utilities/Sass/insideStyle.module.css'
import ContactModule from '../ContactModule/ContactModule'

function ModuloServizi(props) {
    return (
        <div className={insideStyle.cont__insideService} id={props.id}>
            <div className={insideStyle.cont__insideService_description} id="startSection">
                {props.children}
            </div>

            <ContactModule />
        </div>
    )
}

export default ModuloServizi

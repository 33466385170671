import React from 'react'

import Module from './ModuloServizi'

function BonificaAmianto() {
    return (
        <Module id="bonificaAmianto">
                <h2>ESPERTI NELLA <b style={{'color': '#8bc61c'}}>RIMOZIONE DELL'AMIANTO</b></h2>

                <p>
                    La Ever Power è una ditta specializzata nella rimozione dell'amianto, con un team di professionisti dediti allo smaltimento del suddetto materiale in tutta Italia.
                    La nostra Società si occupa di lavorare per Privati, Enti Pubblici e Società Private in Tutto il Territorio Nazionale.
                </p>

                <h2>LE FASI DEL <b style={{'color': '#8bc61c'}}>PIANO DI LAVORO</b></h2>

                <ul>
                    <li>Prelievo di Frammento di MCA e campionamento di fibre di amianto aerodisperse;</li>
                    <li>Analisi in Microscopia Ottica in Contrasto di Fase(MOCF) o Spettrofotometria Infrarossa a Trasformata di Fourier(FTIR) per la caratterizzazione del Materiale e per il Monitoraggio;</li>
                    <li>Certificato di Identificazione del Rifiuto Secondo il Catalogo Europeo dei Rifiuti(CER) in un laboratorio qualificato dal Ministero della Salute;</li>
                    <li>Sopralluoghi Gratuiti con Tecnici Specializzati;</li>
                    <li>Redazione del Piano di Lavoro ai sensi dell'art. 256 Comma 2 del D.Lgs n° 81/2008;</li>
                    <li>Redazione e Consegna al Committente della documentazione attestante la Bonifica Effettuata;</li>
                </ul>

                <h2>COME EFFETTUIAMO LO <b style={{'color': '#8bc61c'}}>SMALTIMENTO DELL'AMIANTO</b></h2>

                <ul>
                    <li>Incapsulamento dei manufatti con prodotto impregnante a Norma CE;</li>
                    <li>Assistenza tecnica in Cantiere e presenza di personale specializzato per il rischio amianto;</li>
                    <li>Rimozione dei manufatti in amianto;</li>
                    <li>Confenzionamento, con doppio telo in polietilene e pedana in segno o sacchi omologati, rimossi ed etichettatura degli stessi;</li>
                    <li>Trasporto con automezzi autorizzati, dei materiali rimossi;</li>
                    <li>Smaltimento in discarica autorizzata per i codici CER 17 06 05* e CER 15 02 02*.</li>
                </ul>

                <h2>RISCHI PER <b style={{'color': '#8bc61c'}}>L'ESPOSIZIONE ALL'AMIANTO</b></h2>

                <p>
                    La potenziale pericolosità dei materiali di amianto dipende dall´eventualità/possibilità che siano rilasciate fibre aerodisperse nell´ambiente che possano venire inalate. Il criterio più importante da valutare in tal senso è rappresentato dalla friabilità dei materiali. I materiali friabili possono liberare fibre spontaneamente per la scarsa coesione interna (soprattutto se sottoposti a fattori di deterioramento quali vibrazioni, correnti d´aria, infiltrazioni di acqua) e possono essere facilmente danneggiati nel corso di interventi di manutenzione o da parte degli occupanti dell´edificio, se sono collocati in aree accessibili.
                </p>

                <p>
                    L´insorgere di patologie tumorali non è sempre legato ad una lunga esposizione a fibre di amianto, ma è stato provato che è possibile contrarre malattie anche con basse esposizioni. Il rischio di esposizione, quindi, non interessa solo i lavoratori che operano su materiali contenenti amianto, ma anche tutte quelle persone che risiedono o frequentano ambienti in cui è presente amianto sotto forma di manufatti.

                    Data la lunga latenza della malattia, nella protezione della popolazione, particolare attenzione dovrà essere posta alla salvaguardia delle popolazioni più giovani (bambini e ragazzi).

                    La pericolosità deriva dalla struttura delle fibre, rigide e sottili, che inalate penetrano facilmente nell´interstizio polmonare. Gli anfiboli, caratterizzati da fibre rigide sono considerati il tipo di amianto più pericoloso per l´uomo.
                </p>
        </Module>
    )
}

export default BonificaAmianto

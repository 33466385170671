import React from 'react'

import Module from './ModuloServizi'

function PannelliFotovoltaici() {
    return (
        <Module id="installazionePannelliFotovoltaici">
            <h2><b style={{'color': '#8bc61c'}}>PANNELLI FOTOVOLTAICI</b></h2>

            <p>
                Sogni una bolletta energetica più leggera? Vuoi conoscere meglio il mondo delle rinnovabili e scoprire tutti i suoi vantaggi?
                <br />
                La Ever Power si occupa anche dell'installazione di pannelli fotovoltaici.
                <br />
                I pannelli solari hanno trovato terreno fertile nel nostro Paese: nel 2016 l’Italia è stata riconosciuta dall’Agenzia internazionale dell’energia (IEA) come prima al mondo per utilizzo dell’energia solare, che copre l’8% dei suoi consumi, seguita da Grecia e Germania.
                Entrare a far parte del mondo delle rinnovabili comporta innumerevoli vantaggi che toccano il nucleo familiare tanto quanto l’ambiente in cui viviamo.
                Il fotovoltaico produce energia pulita, senza emissione di CO2 e riducono la nostra dipendenza dal prezzo delle materie prime alternative presenti sul mercato.
                Inoltre portano ad un tangibile alleggerimento delle bollette e ci permettono di risparmiare denaro che possiamo quindi utilizzare per altri scopi.
            </p>

            <p>
                Oltre a ciò, il valore di una casa equipaggiata con un impianto fotovoltaico aumenta e questo rappresenta un punto di forza in vista di una possibile vendita futura.

                Se vuoi dotare la tua abitazione con i pannelli solari hai scelto il momento perfetto! I prezzi dei pannelli, infatti, sono calati di anno in anno. Ma quanto può costare la loro installazione? Semplice, Richiedi un Preventivo!
            </p>
        </Module>
    )
}

export default PannelliFotovoltaici
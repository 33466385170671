import React from 'react'

import homeStyle from '../../../utilities/Sass/homeStyle.module.css'

import {faqs} from './ListOfFaqs'
import {faqs2} from './ListOfFaqs'

function FAQ(props) {

    return (
        <ul className={homeStyle.cont__home_sectionFAQ}>
            {props.section === "Home" && (
                faqs.map(el => {
                    return (
                        <li key={el.title}>
                            <h3>{el.title}</h3>
                            <p>{el.text}</p>
                        </li>
                    )
                })
            )}

            {props.section === "Inside" && (
                faqs2.map(el => {
                    return (
                        <li key={el.title} id="inside">
                            <h3>{el.title}</h3>
                            <p>{el.text}</p>
                        </li>
                    )
                })
            )}
        </ul>
    )
}

export default FAQ

import React from 'react'

import Module from './ModuloServizi'

function RimozioneGuaine() {
    return (
        <Module id="rimozioneGuaine">
            <h2><b style={{'color': '#8bc61c'}}>RIMOZIONE & TRASPORTO GUAINE BITUMINOSE</b></h2>

            <p>
                Ever Power si occupa dello Smaltimento delle Guaine Bituminose. Le guaine dismesse sono classificate come Rifiuti Speciali e pertanto devono essere smaltite in maniera professionale, seguendo determinati Standard di Smaltimento e Trasporto.
                Essendo quest'ultime Rifiuti Speciali, esse devono essere trasportate e smaltite da una Società Specializzata e Professionale, garantendo la totale sicurezza ambientale.
                Noi di Ever Power garantiamo tale sicurezza, trasportando il carico tramite Mezzi Professionali e adibiti al trasporto dei Rifiuti.
            </p>

            <h2>EVER POWER RILASCIA IL <b style={{'color': '#8bc61c'}}>FIR (Formulario di Identificazione del Rifiuto)</b></h2>

            <p>
                Noi di Ever Power rilasciaremo il Formulario di Identificazione del Rifiuto (FIR) debitamente compilato in ogni sua parte.
                Il FIR è il documento compilato in quadruplice copia di cui la quarta timbrata dall'impianto di smaltimento finale, che attesta, in questo caso, che la guaina è stata smaltita correttamente.
            </p>
        </Module>
    )
}

export default RimozioneGuaine
import image1 from '../../../utilities/img/collabs/logo_green_power2.png'
import image2 from '../../../utilities/img/collabs/campaniaambiente.png'
import image3 from '../../../utilities/img/collabs/caseificiojemma.png'
import image4 from '../../../utilities/img/collabs/gruppofarina.jpg'
import image5 from '../../../utilities/img/collabs/aziendaagricoladicerbo.jpg'
import image6 from '../../../utilities/img/collabs/deco.png'
import image7 from '../../../utilities/img/collabs/santamariacapuavetere2.gif'
import image8 from '../../../utilities/img/collabs/Caserta-Stemma.png'
import image9 from '../../../utilities/img/collabs/gruppofarina.jpg'
import image10 from '../../../utilities/img/collabs/lusciano.png'
import image11 from '../../../utilities/img/collabs/maceratacampania.jpg'
import image12 from '../../../utilities/img/collabs/torre-del-greco.png'


export let arrayImages = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12
]
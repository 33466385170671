import React from 'react'

import {Link} from 'react-router-dom'
import servicesStyle from '../../../utilities/Sass/servicesStyle.module.css'
import Data from './ServicesList.json'

function Services() {
    return (
        <ul className={servicesStyle.cont__services}>
            {Data.map(el => {
                return (
                    <Link key={el.identifier} to={`/servizi/${el.identifier}#startSection`}>
                        <li>
                            <h3>{el.title}</h3>

                            <p>{el.description}</p>
                        </li>
                    </Link>
                )
            })}
        </ul>
    )
}

export default Services
